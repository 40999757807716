@tailwind base;
@tailwind components;
@tailwind utilities;

/* custom.css */
.text-gradient-ai {
    background: linear-gradient(to right, #C084FC, #EC4899, #ED4546);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.button-gradient-ai {
    background: linear-gradient(to right, #C084FC, #EC4899, #ED4546);
    color: white; /* Ensure the text color is white */
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem; /* Rounded corners */
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.button-gradient-ai .black {
    color: black;
}

.button-gradient-ai:hover {
    transform: scale(1.05); /* Slight zoom on hover */
}

.button-gradient-ai_2 {
    background: linear-gradient(to right, #C084FC, #EC4899, #ED4546);
    color: white; /* Ensure the text color is white */
    border: none;
    padding: 0.5rem 1.0rem;
    border-radius: 0.5rem; /* Rounded corners */
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.button-gradient-ai_2:hover {
    transform: scale(1.05); /* Slight zoom on hover */
    border: solid 2px black;
}

.background-gradient-ai_1 {
    background: linear-gradient(to right, #C084FC, #EC4899, #ED4546);
    border: none;
    padding: 0.5rem 1.0rem;
    border-radius: 0.5rem; /* Rounded corners */
    font-weight: normal;
    text-align: center;
    border-radius: 100px;
}

/* Custom Scrollbar Styles */
.custom-scrollbar {
    /* For WebKit browsers */
    &::-webkit-scrollbar {
        width: 4px; /* Width of the scrollbar */
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Track color */
        border-radius: 50px; /* Rounded track ends */
    }
    &::-webkit-scrollbar-thumb {
        background: #888; /* Thumb color */
        border-radius: 50px; /* Rounded corners */
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Thumb color on hover */
    }
}

@media (max-width: 768px) {
    .custom-scrollbar {
        /* For WebKit browsers */
        &::-webkit-scrollbar {
            width: 2px; /* Narrower width for mobile screens */
            height: 2px;
        }
    }
}
